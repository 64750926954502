// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'

import Row from 'antd/lib/row'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/row/style/css'

import Col from 'antd/lib/col'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/StandardPage'

import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: "Principal's Message",
  nakedPageSlug: 'principals-message',
  pageAbstract:
    'Thank you for your interest in Cambridge Innovative School. Within these pages we hope to convey the spirit of our school-its warmth, purpose, thought and soul to equip young children so that they fulfil their potential and unlock potential in many others.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    image1: file(relativePath: { eq: "homepage/principal1.jpg" }) {
      ...defaultImage
    }
  }
`

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const pageStyles = css({
  '& strong': {
    fontWeight: 700,
    color: '#004195',
  },

  '& h1, h2, h3': {
    color: '#004195',
  },

  '& h1': {
    textTransform: 'uppercase',
    fontStyle: 'italic',
    borderTop: '4px solid #004394',
  },

  '& h3': {
    borderTop: '1.5px solid #004394',
  },
})
const pageStyle = pageStyles.toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => (
  <StandardPage className={pageStyle} seoData={seoData}>
    <h1 className="mask-h3">Principal's Message</h1>
    <Row gutter={{ xs: 24, sm: 24, md: 24 }}>
      <Col sm={24} md={16}>
        <p>
          Thank you for your interest in Cambridge Innovative School.
         Within these pages we hope to convey the spirit of our
          school-its warmth, purpose, thought and soul to equip young children, so
          that they fulfil their potential and unlock potential in many others.
        </p>
        <p>
          We intend to provide each student the information needed to make a
          decision. Cambridge Innovative School , is big enough to
          possess prime facilities and yet small enough to identify and provide
          to the individual learner. It is the predominant objective of ours to
          believe that every child deserves and needs a high quality, broad based
          education in order to succeed in life.
        </p>
        <p>
          <i style={{ fontSize: '150%', color: '#004195' }}>
            "Together, We Learn.. Together, We Change"
          </i>
        </p>
        <p>
          Learning opportunities at Cambridge Innovative School are planned and executed to instil
          academic and leadership competencies as well as acquire skills in
          order to confidently and productively participate in the rapidly
          changing times without losing the sight of personal and universal
          goals. Most importantly, not letting imposed prejudices stop them from
          dreaming big and to participate in all opportunities that encourage
          them to lead, compete and succeed.
        </p>
        <p>
          Cambridge Innovative School has many stories of
          achievements and courage, we invite you to experience the encouraging,
          concerned and joyous atmosphere here. The  Student Council along
          with their Teachers would be happy to take you around the school.
        </p>
        <p>
          Warm regards
          <br />
          Kiranjoth Dhillon 
        </p>
      </Col>
      <Col sm={24} md={8}>
        <Img
          fluid={props.data.image1.childImageSharp.fluid}
          className="mask-p"
        />
        <p style={{ textAlign: 'center' }}>
          <strong>Mrs. Kiranjoth Dhillon</strong>
          <br />
          Principal
        </p>
      </Col>
    </Row>
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
